export {
  removeEntriesByCacheKeyPrefix,
  getOrSetInLocalStorageWithExpiryAsync
} from "./localStorageCacheWithExpiry";

export const getValueFromSessionStorage = key => {
  try {
    return sessionStorage.getItem(key);
  } catch (e) {
    return false;
  }
};

export const getValueFromLocalStorage = key => {
  let item = null;

  try {
    item = window.localStorage.getItem(key);

    if (item === "undefined") {
      return undefined;
    }

    return JSON.parse(item);
  } catch (error) {
    return item;
  }
};

export const removeValueFromSessionStorage = key => {
  try {
    sessionStorage.removeItem(key);
  } catch (e) {
    return false;
  }
};

export const hasSessionStorageKey = key => {
  try {
    return !!sessionStorage.getItem(key);
  } catch (e) {
    return false;
  }
};

export const setSessionStorage = (key, value) => {
  try {
    sessionStorage.setItem(key, value);
  } catch (e) {
    return false;
  }
};

export const removeValueFromLocalStorage = key => {
  try {
    return localStorage.removeItem(key);
  } catch (e) {
    return false;
  }
};

const ERROR_NAME = "AsosAuthStorageError";

export class StorageError extends Error {
  constructor(error) {
    super(
      `Error: An issue occurred when accessing the browser storage. ${error.message}`
    );
    this.name = ERROR_NAME;
  }
}

export const isAsosAuthStorageError = potentialErrorObject => {
  return !!(
    potentialErrorObject instanceof Error &&
    potentialErrorObject.name === ERROR_NAME
  );
};

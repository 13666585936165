import {
  getStoreCode,
  getCountryCode,
  getKeyStoreDataversion,
  getDefaultLanguage
} from "../../../template/state/modules/regionalStore";
import clientConfig from "../../../template/clientConfig";
import { getFeaturesFromWindowManifest } from "../features";
import get from "lodash/get";
import { retry } from "./retry";
import { promiseWithTimeout } from "./promiseWithTimeout";
import { isAsosAuthTimeoutError } from "./authTimeoutError";
import { getValueFromLocalStorage } from "../../../helpers/webStorage";
import {
  isAsosAuthStorageError,
  StorageError
} from "../../errors/storageError";

const TOKEN_TYPES = "id_token token";
const IDENTITY_SCOPES = "openid sensitive profile";

const isInterstitialPageEnabled = browseCountry => {
  const featuresFromManifest = getFeaturesFromWindowManifest();
  const interstitialPageFeature = get(
    featuresFromManifest,
    "features[login-interstitial-page]"
  );
  if (
    interstitialPageFeature === true ||
    (Array.isArray(interstitialPageFeature) &&
      interstitialPageFeature.some(
        country => country.toLowerCase() === browseCountry.toLowerCase()
      ))
  ) {
    return true;
  }
  return false;
};

const getRedirectUri = (state, config) => {
  const browseCountry = getCountryCode(state);
  if (isInterstitialPageEnabled(browseCountry)) {
    return `${window.location.origin}${
      config.IDENTITY_SDK_REDIRECT_PATH
    }?lang=${getDefaultLanguage(state)}&store=${getStoreCode(
      state
    )}&countryCode=${browseCountry}&keyStoreDataversion=${getKeyStoreDataversion(
      state
    )}&finalredirecturi=${encodeURIComponent(window.location.href)}`;
  }
  return window.location.href;
};

const isAuthHeaderCached = () => {
  try {
    return !!getValueFromLocalStorage("Asos.TokenManager.token");
  } catch (error) {
    throw StorageError(error);
  }
};

export const initialise = store =>
  new Promise(resolve => {
    const config = clientConfig.get();
    const state = store.getState();
    const lang = getDefaultLanguage(state);

    const identityConfig = {
      lang,
      regionalIsoCode: lang,
      clientId: config.IDENTITY_SDK_CLIENT_ID,
      customerApiUrl: config.IDENTITY_SDK_CUSTOMER_API_URL,
      authenticationHost: config.IDENTITY_SDK_AUTH_HOST,
      identityOrigin: config.IDENTITY_SDK_IDENTITY_ORIGIN,
      responseMode: "json",
      store: getStoreCode(state),
      countryCode: getCountryCode(state),
      keyStoreDataversion: getKeyStoreDataversion(state),
      types: TOKEN_TYPES,
      scope: IDENTITY_SCOPES,
      redirect_uri: getRedirectUri(state, config)
    };
    requirejs(["asos.identity.sdk.config"], configurator => {
      configurator.setConfig(identityConfig);
      requirejs(["asos.identity.web.clientsdk"], sdk => {
        const DEFAULT_TIMEOUT_IN_MS = 15_000;
        const DEFAULT_RETRIES = 2;

        const defaultOptions = {
          timeoutMs: DEFAULT_TIMEOUT_IN_MS,
          retries: DEFAULT_RETRIES
        };

        Object.assign(sdk, {
          isAsosAuthTimeoutError,
          isAuthHeaderCached,
          isAsosAuthStorageError,
          customer: {
            ...sdk.customer,
            anonymousId: async () => (await sdk.profile()).customerAid
          },
          getAuthHeader: ({
            timeoutMs = defaultOptions.timeoutMs,
            retries = defaultOptions.retries
          } = defaultOptions) =>
            promiseWithTimeout(
              retry(
                async () => `Bearer ${await sdk.getAccessToken()}`,
                retries
              ),
              timeoutMs
            )
        });

        resolve(sdk);
      });
    });
  });

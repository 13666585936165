export const retry = async (fn, retriesLeft = 2, interval = 1000) => {
  try {
    return await fn();
  } catch (error) {
    if (retriesLeft) {
      await new Promise(resolve => setTimeout(resolve, interval));
      return retry(fn, retriesLeft - 1, interval + 1000);
    }
    throw error;
  }
};

import { AsosAuthTimeoutError } from "./authTimeoutError";

export async function promiseWithTimeout(promise, timeoutMs) {
  return Promise.race([
    promise,
    new Promise((_, reject) => {
      setTimeout(() => {
        reject(
          new AsosAuthTimeoutError(`Promise timed out after ${timeoutMs}ms`)
        );
      }, timeoutMs);
    })
  ]);
}

const ERROR_NAME = "AsosAuthTimeoutError";

export class AsosAuthTimeoutError extends Error {
  constructor(message) {
    super(message);
    this.name = ERROR_NAME;
  }
}

export const isAsosAuthTimeoutError = potentialErrorObject => {
  return !!(
    potentialErrorObject instanceof Error &&
    potentialErrorObject.name === ERROR_NAME
  );
};
